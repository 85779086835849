import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../layouts/Layout'
import FlexibleContent from '../components/FlexibleContent'


//Component
const PageTemplate = ( props ) => {

  const current_page = props.data.wordpressPage
  const flexible_content = current_page.acf.content_page
  const yoast_meta = current_page.yoast_meta
  
  const current_meta = {
    meta_title:     (yoast_meta) ? yoast_meta.yoast_wpseo_title : null,
    meta_desc:      (yoast_meta) ? yoast_meta.yoast_wpseo_metadesc : null,
  }

  return (
    <Layout 
      current_meta = {current_meta} 
    >

      <FlexibleContent 
        content = {flexible_content}
      />

    </Layout>
  )
}


//Export
export default PageTemplate


//Query
export const pageQuery = graphql`
query currentPageQuery($id: String!) {
  wordpressPage(id: { eq: $id }) {
    acf {
      content_page {
        ...contentFlexible
      }
    }
    yoast_meta {
      yoast_wpseo_title
      yoast_wpseo_metadesc
    }
  }
}
`